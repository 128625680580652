import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  userId: any;
  constructor(private appService: AppService) {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    this.userId = userData?.profile?.id;
  }

  handleError(error: any) {
    let stackDetails: any = {};
    let errorMessage: string = '{}';
    if (error instanceof HttpErrorResponse) {
      stackDetails = {
        url: error.url,
        status: error.status,
        ...this.getStackDetails(error),
      };
      errorMessage = error.message;
    } else if (error instanceof Error) {
      if (environment.devMode) console.log(error);

      stackDetails = this.getStackDetails(error);

      errorMessage = error.message;
    } else {
      try {
        errorMessage = String(error);
      } catch (error) {}
    }

    if (errorMessage && environment.production) {
      this.appService
        .postErrorToBackend({
          error_detail: stackDetails,
          error_message: errorMessage,
          user_id: this.userId,
        })
        .subscribe((resp: any) => {});
    }
  }
  getStackDetails(error: Error): object {
    const stack = error.stack || '';
    const match =
      stack.match(/at\s+(.*)\s+\((.*):(\d+):(\d+)\)/) ||
      stack.match(/at\s+([^ ]+):(\d+):(\d+)/);
    if (match) {
      return {
        methodName: match[1],
        fileName: match[2],
        lineNumber: match[3],
        columnNumber: match[4],
      };
    }
    return {};
  }
}
